import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { EBitfApiRequestPartsNames, EApiRequestPartKeys, EBitfStrapiGraphQlQueryOperator } from '@enums';
import { IBitfPartData } from '@interfaces';
import { IDynamicFilter } from '@web/dynamic-modules/shared/components/filter/shared-filter-panel.interface';
export class FiltersApiRequestPart extends BitfApiRequestPart {
  constructor({
    key,
    partName,
    defaultPartData,
  }: {
    key?: EApiRequestPartKeys;
    partName?: EBitfApiRequestPartsNames;
    defaultPartData?: IBitfPartData;
  } = {}) {
    if (!key) {
      key = EApiRequestPartKeys.FILTERS;
    }
    if (!partName) {
      partName = EBitfApiRequestPartsNames.FILTER;
    }
    super(key, partName, defaultPartData);
  }

  build(): void {
    const formValue = this.defaultPartData.formValue
      ? { ...this.defaultPartData.formValue, ...this.formValue }
      : this.formValue;

    const AndFilterPart: any = {};
    const AndfilterPartsInOr = [];
    const OrFilterPart: any = {};
    // console.log(formValue);
    // Dynamic Filters
    const dynamicFilters: IDynamicFilter[] = formValue.dynamicFilters;
    if (dynamicFilters) {
      dynamicFilters.forEach(filter => {
        if (
          filter.filtrableField &&
          filter.comparator &&
          (filter.value !== undefined ||
            filter.comparator === EBitfStrapiGraphQlQueryOperator.TRUTHY ||
            filter.comparator === EBitfStrapiGraphQlQueryOperator.FALSY ||
            filter.comparator === EBitfStrapiGraphQlQueryOperator.NULL ||
            filter.comparator === EBitfStrapiGraphQlQueryOperator.NOT_NULL)
        ) {
          // it is not a relation we compare a value
          if (!filter.filtrableField.isRelation) {
            // value if is an enum .value if is a string
            let value = filter.value?.name || filter.value !== undefined ? filter.value : null;

            // Date management
            if (value && filter.filtrableField.type === 'date') {
              const date = new Date(value);
              if (
                filter.comparator === EBitfStrapiGraphQlQueryOperator.GREATER_EQUAL ||
                filter.comparator === EBitfStrapiGraphQlQueryOperator.LOWER_EQUAL
              ) {
                date.setHours(12, 0, 0);
                value = date.toISOString().split('T')[0];
              }
            }

            // Datetime management
            if (value && filter.filtrableField.type === 'datetime') {
              const date = new Date(value);
              if (filter.comparator === EBitfStrapiGraphQlQueryOperator.GREATER_EQUAL) {
                date.setHours(0, 0, 0);
                value = date;
              } else if (filter.comparator === EBitfStrapiGraphQlQueryOperator.LOWER_EQUAL) {
                date.setHours(23, 59, 59);
                value = date;
              }
            }

            if (filter.filtrableField.isNested && filter.filtrableField.filterProperty.includes('.')) {
              const keys = [...filter.filtrableField.filterProperty.split('.'), filter.comparator];
              let obj = {};
              for (let i = keys.length - 1; i > 0; i--) {
                obj = { [keys[i]]: i === keys.length - 1 ? value : obj };
              }
              AndFilterPart[keys[0]] = obj;
            } else {
              if (
                filter.comparator === EBitfStrapiGraphQlQueryOperator.TRUTHY ||
                filter.comparator === EBitfStrapiGraphQlQueryOperator.FALSY
              ) {
                AndFilterPart[filter.filtrableField.property] = {
                  eq: filter.comparator === EBitfStrapiGraphQlQueryOperator.TRUTHY ? true : false,
                };
              } else {
                AndFilterPart[filter.filtrableField.property] = {
                  [filter.comparator]:
                    filter.comparator === EBitfStrapiGraphQlQueryOperator.NULL ||
                    filter.comparator === EBitfStrapiGraphQlQueryOperator.NOT_NULL
                      ? true
                      : value,
                };
              }
            }
          } else {
            // It's a relation we compare via id
            // with comparator 'vuoto' we've to check the id
            if (
              filter.comparator === EBitfStrapiGraphQlQueryOperator.NULL ||
              filter.comparator === EBitfStrapiGraphQlQueryOperator.NOT_NULL
            ) {
              AndFilterPart[filter.filtrableField.property] = { id: { [filter.comparator]: true } };
            } else {
              // it is a multiple checkbox
              if (
                filter.filtrableField.type === 'multiSelect' ||
                filter.filtrableField.type === 'autocomplete-chips'
              ) {
                if (filter.filtrableField.filterProperties) {
                  AndfilterPartsInOr.push({
                    or: filter.filtrableField.filterProperties.map(property => ({
                      [property]: {
                        [filter.comparator === EBitfStrapiGraphQlQueryOperator.NOT_EQUAL ||
                        filter.comparator === EBitfStrapiGraphQlQueryOperator.NOT_CONTAINS
                          ? 'and'
                          : 'or']: filter.value.map(filterValue => ({
                          id: { [filter.comparator]: parseInt(filterValue.id, 10) },
                        })),
                      },
                    })),
                  });
                } else {
                  AndFilterPart[filter.filtrableField.property] = {
                    [filter.comparator === EBitfStrapiGraphQlQueryOperator.NOT_EQUAL ||
                    filter.comparator === EBitfStrapiGraphQlQueryOperator.NOT_CONTAINS
                      ? 'and'
                      : 'or']: filter.value.map(filterValue => ({
                      id: { [filter.comparator]: parseInt(filterValue.id, 10) },
                    })),
                  };
                }
              }
            }
          }
        }
      });
    }

    // Typology
    if (formValue.t_1s?.length) {
      AndFilterPart.t_1s = { or: formValue.t_1s.map(typology => ({ id: { eq: typology.id } })) };
    }
    if (formValue.t_2s?.length) {
      AndFilterPart.t_2s = { or: formValue.t_2s.map(typology => ({ id: { eq: typology.id } })) };
    }
    if (formValue.t_3s?.length) {
      AndFilterPart.t_3s = { or: formValue.t_3s.map(typology => ({ id: { eq: typology.id } })) };
    }
    if (formValue.t_4s?.length) {
      AndFilterPart.t_4s = { or: formValue.t_4s.map(typology => ({ id: { eq: typology.id } })) };
    }

    // Sale
    if (formValue.is_sold !== undefined) {
      AndFilterPart.is_sold = {
        eq: formValue.is_sold,
      };
    }

    // Visibility
    if (formValue.is_visible_to_editor !== undefined && formValue.is_visible_to_editor !== null) {
      AndFilterPart.is_visible_to_editor = {
        eq: formValue.is_visible_to_editor,
      };
    }
    if (formValue.is_visible_to_collaborator !== undefined && formValue.is_visible_to_collaborator !== null) {
      AndFilterPart.is_visible_to_collaborator = {
        eq: formValue.is_visible_to_collaborator,
      };
    }
    if (
      formValue.is_visible_to_ufficio_stile !== undefined &&
      formValue.is_visible_to_ufficio_stile !== null
    ) {
      AndFilterPart.is_visible_to_ufficio_stile = {
        eq: formValue.is_visible_to_ufficio_stile,
      };
    }
    if (formValue.is_visible_to_viewer !== undefined && formValue.is_visible_to_viewer !== null) {
      AndFilterPart.is_visible_to_viewer = {
        eq: formValue.is_visible_to_viewer,
      };
    }
    // Suit
    // NOTE Disabled suit typology
    // We filter not at suit level but suit > suit type
    // if (formValue.suitTypologies && formValue.suitTypologies.length) {
    //   const shouldLoadAllSuits = formValue.suitTypologies.find(suitTypology => suitTypology.id === -1);
    //   const shouldLoadNotInSuits = formValue.suitTypologies.find(suitTypology => suitTypology.id === -2);
    //   if (shouldLoadAllSuits) {
    //     filterPart.suit = { id: { notNull: true } };
    //   } else if (shouldLoadNotInSuits) {
    //     filterPart.suit = { id: { null: true } };
    //   } else {
    //     filterPart.suit = {
    //       s_t_1: {
    //         or: formValue.suitTypologies.map(suitType => ({
    //           id: {
    //             eq: parseInt(suitType.id, 10),
    //           },
    //         })),
    //       },
    //     };
    //   }
    // }

    // Suit
    if (formValue.suitTypologies) {
      const shouldLoadAllSuits = formValue.suitTypologies.id === -1;
      const shouldLoadNotInSuits = formValue.suitTypologies.id === -2;
      if (shouldLoadAllSuits) {
        AndFilterPart.suit = { id: { notNull: true } };
      } else if (shouldLoadNotInSuits) {
        AndFilterPart.suit = { id: { null: true } };
      }
    }

    // Link
    if (formValue.linkTypologies) {
      const shouldLoadAllLinks = formValue.linkTypologies.id === -1;
      const shouldLoadNotInLinks = formValue.linkTypologies.id === -2;
      if (shouldLoadAllLinks) {
        OrFilterPart.links_from = { id: { notNull: true } };
        OrFilterPart.links_to = { id: { notNull: true } };
      } else if (shouldLoadNotInLinks) {
        AndFilterPart.links_from = { id: { null: true } };
        AndFilterPart.links_to = { id: { null: true } };
      }
    }

    // Folders
    if (formValue.folders && formValue.folders.length) {
      AndFilterPart.folders = {
        or: formValue.folders.map(folder => ({
          id: {
            eq: parseInt(folder.id, 10),
          },
        })),
      };
    }

    if (formValue.is_image_folder !== undefined) {
      AndFilterPart.is_image_folder = {
        eq: formValue.is_image_folder,
      };
    }

    // Loans
    if (formValue.loans && formValue.loans.length) {
      AndFilterPart.archive_loans = {
        loan: {
          or: formValue.loans.map(loan => ({
            id: {
              eq: parseInt(loan.id, 10),
            },
          })),
        },
      };
    }

    this.part = {
      and: [...Object.keys(AndFilterPart).map(key => ({ [key]: AndFilterPart[key] })), ...AndfilterPartsInOr],
      or: [...Object.keys(OrFilterPart).map(key => ({ [key]: OrFilterPart[key] }))],
    };
  }
}
